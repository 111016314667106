import React, {useState} from 'react'
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Studio from '../images/studio';
import Form from '../components/Form';
import { OutboundLink} from 'gatsby-plugin-google-gtag';


const ContactPage = () => {
    return (
        <>
            <SEO title="Contact" description="Monss offers design, development, and custom software solutions for your business. Contact us today for a hassle-free consultation."></SEO>
            <div className="container mx-auto p-5">
                <div className="flex flex-col md:flex-row">
                    <div className=" md:w-3/4">
                        <Form></Form>
                    </div>
                    <div className=" md:w-2/4">
                        <SideBar />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactPage

ContactPage.defaultProps = {
    theme: 'indigo'
};

ContactPage.propTypes = {
    theme: PropTypes.string.isRequired
};




const SideBar = () => (
    <div className="w-full py-16 md:pl-20">
        <Studio className="mt-12"/>
        <div className="py-8">
            <p className="font-sans text-xl">Address</p>
            <OutboundLink href="https://www.google.com/maps/place/The+Printman+Printers+and+Publishers,+69-70A+Industrial+Estate+Rd,+Phase-1+Phase+1+Hayatabad,+Peshawar,+Khyber+Pakhtunkhwa,+Pakistan/@33.9809356,71.4446322,15z/data=!4m5!1m2!2m1!1sthe+printman!3m1!1s0x38d910607ac5647d:0x5d3414146a5d133e" target="blank" rel="noopener noreferrer">
                <p className="font-bold text-lg transition duration-300 ease-in-out underline hover:no-underline hover:text-yellow-500">
                    Plot# 69-70A, Industrial Estate, Hayatabad, Peshawar.</p>
            </OutboundLink>
        </div>
        <div className="py-10">
            <p className="font-sans text-xl">Contact Information</p>
            <li>
            <OutboundLink href="tel:091-5891325" className="text-lg opacity-80 hover:opacity-100 underline">Office: 92-91-589-1325</OutboundLink>
            </li>
            <li>
            <OutboundLink href="https://wa.me/923085993550" className="text-lg opacity-80 hover:opacity-100 underline">Whatsapp: 92-308-599-3550</OutboundLink>
            </li>
            <li>
            <OutboundLink href="tel:+92-300-9594990" className="text-lg opacity-80 hover:opacity-100 underline">Mobile: 92-300-959-4990</OutboundLink>
            </li>
            <li>
                <OutboundLink href="mailto:atta@theprintmanpublishers.com?subject=New Project Inquiry from website" className="text-lg opacity-80 hover:opacity-100 underline">atta@theprintmanpublishers.com</OutboundLink>
            </li>
        </div>
        <div className="flex-1">
            <p className="font-sans text-xl">Directions</p>
            <div>
                <div ><iframe width="100%" height="300" scrolling="no"  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(The%20printman%20publishers)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://www.maps.ie/route-planner.htm"></a></div>
            </div>
        </div>
    </div>
)